.root {
    width: 305px;
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.titleContent {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    text-align: center;
    margin-bottom: 35px;
}

.description {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}

.resultText {
    text-align: justify;
    background: #d9534f;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-left: 10;
}

.alert {
    text-align: center;
    margin-bottom: 10;
}

.alertText {
    color: #d9534f;
}

.result-content {
    display: flex;
    flex-direction: column;
    text-align: justify;
}

.result {
    box-sizing: border-box;
    display: flex;
    text-align: justify;
    word-break: break-word;
    /* background: #4f86d9; */
}

.root .div-line-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.root .div-line-content .div-line {
    width: 80%;
    border: 1px solid #aaa;
    margin: 15px;
}